import React, { useState } from "react";
import { Form, Button, Container, Row, Col, Alert } from "react-bootstrap";
import CONTACT_API from "../services/ContactService";
import "./ContactForm.css";
import { IoCallSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";

const ContactForm = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    countryCode: "",
    contact: "",
    message: "",
  });

  const [responseMessage, setResponseMessage] = useState("");
  const [responseError, setResponseError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponseMessage("");
    setResponseError("");

    try {
      const response = await CONTACT_API.AddContact({
        name: form.name,
        contact: form.contact,
        email: form.email,
        message: form.message,
      });

      if (response.status !== 200) {
        throw new Error("Network response was not ok");
      }

      setResponseMessage(
        "Form submitted successfully! We'll get back to you soon!"
      );
      setForm({
        name: "",
        email: "",
        countryCode: "",
        contact: "",
        message: "",
      });
    } catch (error) {
      console.error("Error:", error);
      setResponseError("Something went wrong, please try again.");
    }
  };

  return (
    <div className="bg-contact-white pt-2">
      <div className="text-center findus">Find Us</div>
      <div className="text-center fs-1 fw-bold contact-form-header">
        Contact Us
      </div>
      <div className="bg-contact-white pt-2">
        <div className="container">
          <div className="row">
            <div className="col cont-detail-box col-xl-6 col-lg-6 col-md-6 col-sm-3 col-xs-3">
              <div className="cont-detail">
                <div className="cont-detail-icon">
                  <IoCallSharp />
                </div>
                <div className="cont-detail-text">
                  <p>
                    <span className="cont-head">Call Us</span>
                    <br />
                    +91 7024166889
                  </p>
                </div>
              </div>
              <div className="cont-detail">
                <div className="cont-detail-icon">
                  <MdEmail />
                </div>
                <div className="cont-detail-text">
                  <p>
                    <span className="cont-head">Email Us</span>
                    <br />
                    tanisha@investist.in
                  </p>
                </div>
              </div>
              <div className="cont-detail">
                <div className="cont-detail-icon">
                  <FaLocationDot />
                </div>
                <div className="cont-detail-text">
                  <p>
                    <span className="cont-head">Address</span>
                    <br />
                    New Palasia, Indore, India
                  </p>
                </div>
              </div>
            </div>
            <div className="col col-xl-6 col-lg-6 col-md-6 col-sm-3 col-xs-3">
              <Container className="contact-form-container2">
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group controlId="formName">
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          name="name"
                          value={form.name}
                          onChange={handleChange}
                          required
                          className="custom-input2"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group controlId="formContact">
                        <Form.Control
                          type="text"
                          placeholder="Contact No."
                          name="contact"
                          value={form.contact}
                          onChange={handleChange}
                          required
                          className="custom-input2"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="mb-3">
                      <Form.Group controlId="formEmail">
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          name="email"
                          value={form.email}
                          onChange={handleChange}
                          required
                          className="custom-input2"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group controlId="formMessage" className="mb-3">
                        <Form.Control
                          as="textarea"
                          rows={4}
                          placeholder="Message"
                          name="message"
                          value={form.message}
                          onChange={handleChange}
                          required
                          className="custom-input2"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="text-start">
                    <Button
                      variant="primary"
                      type="submit"
                      className="submit-btn"
                      style={{ backgroundColor: "#6fd441", color: "black" }}
                    >
                      Send Message
                    </Button>
                  </div>
                </Form>
                {responseMessage && (
                  <Alert variant="success" className="mt-3">
                    {responseMessage}
                  </Alert>
                )}
                {responseError && (
                  <Alert variant="danger" className="mt-3">
                    {responseError}
                  </Alert>
                )}
              </Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
