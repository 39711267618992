import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import INVEST_API from '../services/InvestService'; // Adjust the path as needed
import './InvestForm.css';

const InvestForm = ({ propertyName, size, onClose }) => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    countryCode: '',
    contact: '',
    message: '',
  });

  const [responseMessage, setResponseMessage] = useState('');
  const [responseError, setResponseError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponseMessage('');
    setResponseError('');

    try {
      const response = await INVEST_API.AddInvest({ ...form, propertyName, size });

      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }

      const successMessage = `You have shown your interest in ${propertyName}. We'll get back to you soon.`;
      setResponseMessage(successMessage);
      toast.success(successMessage);

      setForm({
        name: '',
        email: '',
        countryCode: '',
        contact: '',
        message: '',
      });

      onClose();
    } catch (error) {
      console.error('Error:', error);
      const errorMessage = 'Something went wrong, please try again.';
      setResponseError(errorMessage);
      toast.error(errorMessage);
    }
  };

  return (
    <div className="bg-white pt-2">
      <Container className="contact-form-container">
        <div className="text-center fs-3 fw-semibold contact-form-header">
          You have shown interest in {propertyName}
        </div>
        <div className='fs-6 text-center'>Please fill the form below and our team will reach out to you soon<br/></div>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group controlId="formName">
                <Form.Control
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={form.name}
                  onChange={handleChange}
                  required
                  className="custom-input"
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group controlId="formContact">
                <Form.Control
                  type="text"
                  placeholder="Contact No."
                  name="contact"
                  value={form.contact}
                  onChange={handleChange}
                  required
                  className="custom-input"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Group controlId="formEmail">
                <Form.Control
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                  required
                  className="custom-input"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="formMessage" className="mb-3">
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Message"
                  name="message"
                  value={form.message}
                  onChange={handleChange}
                  required
                  className="custom-input"
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="text-start">
            <Button variant="primary" type="submit" className="submit-btn">
              Submit
            </Button>
            <Button variant="secondary" onClick={onClose} className="close-btn mt-2 ml-2">
              Close
            </Button>
          </div>
        </Form>
        {responseMessage && (
          <Alert variant="success" className="mt-3">
            {responseMessage}
          </Alert>
        )}
        {responseError && (
          <Alert variant="danger" className="mt-3">
            {responseError}
          </Alert>
        )}
      </Container>
    </div>
  );
};

export default InvestForm;
