import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { AuthContext } from "../helper/AuthenticationState";
import logo from "../assets/logo3.png";
import "./Navbar.css";
import USER_API from "../services/UserService";

const Navbar = () => {
  const { currentUser, LogOutUser } = useContext(AuthContext);
  const auth = getAuth();
  const [offcanvasOpen, setOffcanvasOpen] = useState(false);

  const handleLogout = async () => {
    try {
      const res = await USER_API.Logout();
      if (res.status === 200) {
        await signOut(auth);
        LogOutUser();
      }
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  const toggleOffcanvas = () => {
    setOffcanvasOpen(!offcanvasOpen);
  };

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
        <button
          className="navbar-toggler shadow-none border-0"
          type="button"
          aria-controls="offcanvasNavbar"
          aria-label="Toggle navigation"
          onClick={toggleOffcanvas}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`offcanvas offcanvas-start ${offcanvasOpen ? "show" : ""}`}
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          style={{ visibility: offcanvasOpen ? "visible" : "hidden" }}
        >
          <div className="offcanvas-header text-white border-bottom">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
              <img
                src={logo}
                alt="Logo"
                className="logo text-center"
                style={{ maxWidth: "10rem", textAlign: "center" }}
              />
            </h5>
            <button
              type="button"
              className="btn-close btn-close-dark shadow-none"
              onClick={closeOffcanvas}
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body d-flex flex-column flex-lg-row p-4 p-lg-0">
            <ul className="navbar-nav justify-content-center align-items-center fs-5 flex-grow-1 pe-3 gap-3">
              {/* <li className="nav-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/"
                  onClick={closeOffcanvas}
                >
                  Home
                </Link>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link" to="/news" onClick={closeOffcanvas}>
                News
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/properties"
                  onClick={closeOffcanvas}
                >
                  Properties
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about" onClick={closeOffcanvas}>
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/contact"
                  onClick={closeOffcanvas}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
            {currentUser ? (
              <div className="d-flex flex-column flex-lg-row justify-content-center align-item-center gap-3">
                <span className="text-decoration-none pt-3 mt-1 text-center">
                  Welcome, {currentUser.name || "User"}!
                </span>
                <button
                  className="text-white text-decoration-none px-3 py-1 rounded-1 text-center"
                  style={{ backgroundColor: "#6fd441", alignSelf: "center" }}
                  onClick={() => {
                    handleLogout();
                    closeOffcanvas();
                  }}
                >
                  Logout
                </button>
              </div>
            ) : (
              <div className="d-flex flex-column flex-lg-row justify-content-center align-item-center gap-3">
                <Link
                  className="text-decoration-none pt-3 mt-1 text-center"
                  to="/login"
                  onClick={closeOffcanvas}
                  style={{ color: "#0A3174" }}
                >
                  Login
                </Link>
                <Link
                  className="text-white text-decoration-none px-3 py-2 rounded-1 text-center"
                  style={{ backgroundColor: "#6fd441", alignSelf: "center" }}
                  to="/signup"
                  onClick={closeOffcanvas}
                >
                  Sign Up
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;