import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./About.css";
import { FaLinkedin } from "react-icons/fa6";
import manish from "../assets/manish_2.png";
import ayush from "../assets/ayush_gorani.jpg";
import FAQs2 from "../components/FAQs2";

const About = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="about-container">
      <div className="somebg">
        <div className="about-overlay">
          <h1>About Us</h1>
          <p className="about-paragraph">
            Welcome to Investist, where our mission is to make fractional real
            estate investing accessible to everyone. Founded by Ayush Gorani and
            Maniesh Sinha, we believe that everyone deserves a stake in the real
            estate market, no matter their financial background. By combining
            innovative technology with industry expertise, we offer a seamless
            platform for fractional ownership of premium real estate assets.
            Whether you’re aiming to diversify, build wealth, or explore new
            opportunities, Investist is here to support your financial journey.
            Join us and transform the way you invest in real estate.
          </p>
        </div>
      </div>

      <div className="about-section-separator"></div>

      <div className="about-cards">
        <div className="about-card">
          <h2>Vision</h2>
          <p className="text-start">To build a trusted real estate company.</p>
        </div>
        <div className="about-card">
          <h2>Mission</h2>
          <p style={{ textAlign: "start", wordBreak: "" }}>
            Our mission is to create investor confidence and convenience. To
            achieve this, we offer hassle-free investments with reliable returns
            and growth. Our transparent process makes it easy for investors to
            participate.
            <br />
            <br /> We also aim to ensure everyone in our organization benefits,
            creating a supportive and rewarding work environment.
          </p>
        </div>
        <div className="about-card">
          <h2>Values</h2>
          <p className="text-start">
            We are driven by the following values:
            <br />
            {/* <br /> */}
            <strong>Ethics driven:</strong> Honesty and transparency at every
            stage.
            <br />
            {/* <br /> */}
            <strong>Investor-centric:</strong> Create value for investors.
            <br />
            {/* <br /> */}
            <strong>Quality focused:</strong> Our attention to detail and
            thoroughness define us.
          </p>
        </div>
      </div>

      <div className="team-section">
        <h2 className="">Meet Our Team</h2>
        <div className="team-cards">
          <div className="team-card">
            <img src={ayush} alt="Team Member 1" className="team-image" />
            <div className="team-info">
              <div className="team-info-text">
                <p>Co-Founder and CEO</p>
                <h3>Ayush Gorani</h3>
                <h6>
                  Ayush Gorani is the Founder and CEO of Investist, a real
                  estate investment platform. He is a seasoned investor focused
                  on making real estate more accessible through fractional
                  ownership.
                </h6>
              </div>

              <a
                href="https://www.linkedin.com/in/ayushgorani?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin className="fs-2" style={{ color: "#0A3174" }} />
              </a>
            </div>
          </div>
          <div className="team-card">
            <img src={manish} alt="Team Member 2" className="team-image" />
            <div className="team-info">
              <div className="team-info-text">
                <p>Co-Founder and COO</p>
                <h3>Maniesh Sinha</h3>
                <h6>
                  Maniesh Sinha is the Co-Founder and COO of Investist, driving
                  operational excellence and strategic growth in the real estate
                  investment sector.
                </h6>
              </div>

              <a
                href="https://www.linkedin.com/in/maneish-sinha-503637180?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin className="fs-2" style={{ color: "#0A3174" }} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <FAQs2 />
    </div>
  );
};

export default About;
