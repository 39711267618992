import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Content4.css";
import invest_img from "../assets/investImg.png";
import earn_img from "../assets/earnImg.png";
import sell_img from "../assets/sellImg.png";
import track_img from "../assets/trackImg.png";

const Content4 = () => {
  const [isActive, setIsactive] = useState(1);
  return (
    <section className="how-it-works py-5">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2 className="section-title-4 text-start">How it works</h2>
            <p className="section-subtitle-4 text-start">
              Investing with us is easy, transparent, & completely online!
            </p>
          </div>
        </div>
        <div class="row">
          <div className="col-6">
            <div
              class="nav flex-column nav-pills me-3"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              {[
                {
                  id: 1,
                  title: "INVEST",
                  text: "Invest in high-yield, Grade-A commercial assets",
                  image: invest_img,
                  text2:"All assets undergo a multi-level qualification process to get you the best return possible."
                },
                {
                  id: 2,
                  title: "EARN",
                  text: "Earn a passive income while building long-term wealth",
                  image: earn_img,
                  text2:"Your investments earn you a monthly rental return and increase in value over time."
                },
                {
                  id: 3,
                  title: "TRACK",
                  text: "Track your portfolio performance and get regular updates",
                  image: track_img,
                  text2:"Get detailed reports on your returns and regular updates on your investment."
                },
                {
                  id: 4,
                  title: "SELL",
                  text: "Make a profitable exit with our liquidity options",
                  image: sell_img,
                  text2:"You can exit your investment in multiple ways, giving you flexibility and greater liquidity."
                },
              ].map((item, index) => (
                <div
                  class={`${
                    item.id === isActive ? "pillsItemActive" : "pillsItem"
                  } pillsCont`}
                  id={`v-pills-${item.id}-tab`}
                  data-bs-toggle="pill"
                  data-bs-target={`#v-pills-${item.id}`}
                  role="tab"
                  aria-controls={`v-pills-${item.id}`}
                  aria-selected="true"
                  onClick={() => {
                    setIsactive(item.id);
                  }}
                >
                  <p className="pillsTitle">{item.title}</p>
                  <p className="pillsText">{item.text}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="col-6 align-self-center">
            <div class="tab-content" id="v-pills-tabContent">
              {[
                {
                  id: 1,
                  title: "INVEST",
                  text: "Invest in high-yield, Grade-A commercial assets",
                  image: invest_img,
                  text2:"All assets undergo a multi-level qualification process to get you the best return possible."
                },
                {
                  id: 2,
                  title: "EARN",
                  text: "Earn a passive income while building long-term wealth",
                  image: earn_img,
                  text2:"Your investments earn you a monthly rental return and increase in value over time."
                },
                {
                  id: 3,
                  title: "TRACK",
                  text: "Track your portfolio performance and get regular updates",
                  image: track_img,
                  text2:"Get detailed reports on your returns and regular updates on your investment."
                },
                {
                  id: 4,
                  title: "SELL",
                  text: "Make a profitable exit with our liquidity options",
                  image: sell_img,
                  text2:"You can exit your investment in multiple ways, giving you flexibility and greater liquidity."
                },
              ].map((item, index) => (
                <div
                  class={`tab-pane fade show ${
                    item.id === isActive && "active"
                  }`}
                  id={`v-pills-${item.id}`}
                  role="tabpanel"
                  aria-labelledby={`v-pills-${item.id}-tab`}
                >
                  <div className="imag-container"><img src={item.image} alt="" className="w-100 h-100"/></div>
                  <div className="text2-container">{item.text2}</div>
                  
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Content4;
